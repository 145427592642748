import Vue from 'vue'

import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import animate from 'animate.css'

import '@/styles/index.scss' // global css

import App from './App'
import router from './router'
import store from './store'

import '@/icons' // icon
import '@/permission' // permission control

import AMap from 'vue-amap';//高德地图
Vue.use(ElementUI, { locale })
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(animate)
Vue.use(AMap);//高德地图

import Print from '@/api/print'
Vue.use(Print) // 注册

import audio from 'vue-mobile-audio'
Vue.use(audio)

Vue.config.productionTip = false

Vue.prototype.isAuth = (key) => {
  return (store.getters.permissions || '[]').indexOf(key) !== -1 || false
}
AMap.initAMapApiLoader({
  key: '',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
  uiVersion: '1.0.11' // 版本号
});//高德地图

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
