<template>
  <el-dialog
    :title="!dataForm.id ? '修改密码' : '修改密码'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" v-if="visible" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
     v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="登录帐号" disabled style="width:90%;"></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="password" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.password" type="password" placeholder="原密码" style="width:90%;"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword" :class="{ 'is-required': !dataForm.id }" >
        <el-input v-model="dataForm.newPassword" type="password" placeholder="新密码" style="width:90%;"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="comfirmPassword" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.comfirmPassword" type="password" placeholder="确认密码" style="width:90%;"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { updatePassword } from "@/api/sys";

  export default {
    data () {
      var validatePassword = (rule, value, callback) => {
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("密码不能为空"));
        } else {
          callback();
        }
      };
      var validateNewPassword = (rule, value, callback) => {
        if(value.length < 4){
          callback(new Error("密码长度至少4位"));
        }
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("新密码不能为空"));
        } else {
          callback();
        }
      };
      var validateComfirmPassword = (rule, value, callback) => {
        if(value.length < 4){
          callback(new Error("密码长度至少4位"));
        }
        if (!this.dataForm.id && !/\S/.test(value)) {
          callback(new Error("确认密码不能为空"));
        } else if (this.dataForm.newPassword !== value) {
          callback(new Error("确认密码与密码输入不一致"));
        } else {
          callback();
        }
      };
      return {
        visible: false,
        dataLoading: false,
        dataForm: {
          id: 0,
          userName: "",
          password: "",
          newPassword: "",
          comfirmPassword: "",
        },
        dataRule: {
          password: [{ required: true, message: "原密码不能为空", trigger: "blur" },
                     { validator: validatePassword, trigger: "blur" }],
          newPassword: [{ required: true, message: "新密码不能为空", trigger: "blur" },
                        { validator: validateNewPassword, trigger: "blur" }],
          comfirmPassword: [{ required: true, message: "确认密码不能为空", trigger: "blur" },
                            { validator: validateComfirmPassword, trigger: "blur" }]
        }
      }
    },
    methods: {
      init(id,name) {
        this.dataForm.id = id;
        this.dataForm.userName = name;
        this.dataForm.password = '';
        this.dataForm.newPassword = '';
        this.dataForm.comfirmPassword = '';
        this.visible = true
        // this.$nextTick(() => {
        //   this.dataForm.userName = '';
          // if (this.dataForm.id) {
          //   var params = {
          //     userId: this.dataForm.id,
          //   };
          //   userInfo(params).then(({data}) => {
          //     if (data && data.code === 0) {
          //       this.dataForm.userName = data.user.userName;
          //       this.dataForm.password = '';
          //       this.dataForm.newPassword = '';
          //       this.dataForm.comfirmPassword = '';
          //     }
          //   })
          // }
        // })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.dataLoading = true;
            var params = {
              userId: this.dataForm.id || undefined,
              password: this.dataForm.password,
              newPassword: this.dataForm.comfirmPassword,
            }
            updatePassword(params).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
	              	this.dataLoading = false;
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
                this.dataLoading = false;
              }
            })
          }
        })
      }
    }
  }
</script>
