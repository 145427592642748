import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout'

/** note: submenu only apppear when children.length>=1
*   detail see  https://panjiachen.github.io/vue-element-admin-site/#/router-and-nav?id=sidebar
**/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index'),
      name: '首 页',
      meta: { title: '首 页', icon: 'dashboard', noCache: true }
    }]
  },
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: true,
  //   children: [{
  //     path: '/mall/goods/attrValue',
  //     component: () => import('@/views/mall/goods/attrValue/index'),
  //     name: '属性值列表',
  //     meta: { title: '属性值列表', icon: 'example', noCache: true }
  //   }]
  // },
  /*新增商品*/
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: true,
  //   children: [{
  //     path: '/mall/goods/main/add',
  //     component: () => import('@/views/mall/goods/main/components/main-add-or-update'),
  //     name: '商品管理',
  //     meta: { title: '添加商品', icon: 'example', noCache: true }
  //   }]
  // },
    /*商品详情*/
    // {
    //   path: '',
    //   component: Layout,
    //   hidden: true,
    //   children: [{
    //     path: '/mall/goods/main/detail',
    //     component: () => import('@/views/mall/goods/main/components/main-detail'),
    //     name: '商品管理',
    //     meta: { title: '商品详情', icon: 'example', noCache: true }
    //   }]
    // },
  /*新增推荐商品页面*/
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: true,
  //   children: [{
  //     path: '/mall/goods/recommend/add',
  //     component: () => import('@/views/mall/goods/recommend/components/goods-recommend'),
  //     name: '商品推荐',
  //     meta: { title: '添加推荐', icon: 'example', noCache: true }
  //   }]
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  { path: '*', redirect: '/404', hidden: true }
]
