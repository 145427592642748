import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.NODE_ENV==='production'?process.env.VUE_APP_BASE_API:'/api', // api的base_url
  timeout: 300000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  let url=config.url;
  if(config.method === 'get'){
    url.indexOf('?') ===-1 ?config.url=url+'?_='+(new Date().getTime()):config.url=url+'&_='+(new Date().getTime());
  }
  if (store.getters.token) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 401) {
      store.dispatch('FedLogOut').then(() => {
        location.reload()
      })
    } else {
      return response
    }
  },
  error => {
    console.log('err' + error)// for debug
    Message({
      message: error.message, // ,
      type: 'error',
      duration: 10 * 1000
    })
    return Promise.reject(error)
  })

export default service
