<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
    <breadcrumb></breadcrumb>
    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img class="user-avatar" src="../../../assets/img/avatar.png" />
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu class="user-dropdown" slot="dropdown">
          <el-dropdown-item>账户：{{vo.userName}}</el-dropdown-item>
          <el-dropdown-item>角色：{{vo.roleName}}</el-dropdown-item>
          <router-link class="inlineBlock" to="/">
            <el-dropdown-item>首 页</el-dropdown-item>
          </router-link>
          <el-dropdown-item>
              <el-button type="primary" size="small" @click="updatepasswordHandle(vo.userId,vo.userName)">修改密码</el-button>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span @click="logout" style="display:block;">登 出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <update-password v-if="updatePasswordVisible" ref="updatePassword"></update-password>
    <!-- <svg-icon class="float-right" icon-class="star"></!-->
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import UpdatePassword from "./updatepassword";
import {nameAndRole} from '@/api/sys';
import Cookies from 'js-cookie';

const deviceId='deviceId'
export default {
  data() {
    return {
      vo:{},
      deviceList:[],
      updatePasswordVisible: false,
      device:{
        deviceId:''
      }
    }
  },
  components: {
    Breadcrumb,
    Hamburger,
    UpdatePassword,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar"])
  },
  created() {
    this.getNameAndRole();
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
      Cookies.set(deviceId, '')
    },
    //导航栏下拉框
    changeValue(val){
      Cookies.set(deviceId,val)
    },
    //修改密码
    updatepasswordHandle(id,name) {
      this.updatePasswordVisible = true;
      console.log(id)
      if (id == null) {
        this.$message.error("账号需要配置，请联系管理员");
        return;
      }
      this.$nextTick(() => {
        this.$refs.updatePassword.init(id,name)
      })
    },
    //获取用户账号和用户角色
    getNameAndRole(){
      let params = {}
      nameAndRole(params).then(({data}) => {
        if (data && data.code === 0) {
          this.vo = data.vo;
        } else {
          this.vo = {}
        }
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    width: 20%;
    margin-right: 100px;
  }
  .warning {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    font-size: 32px;
  }
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
</style>

